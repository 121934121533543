import React from "react";
import ReactDOM from 'react-dom';

import {bindable, customElement, inject, noView} from 'aurelia-framework';

import {EventAggregator} from "aurelia-event-aggregator";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {FlashService} from "../flash/flash-service";
import {CurrencyValueConverter} from "../currency/currency-value-converter";
import {DateValueConverter, PercentageValueConverter} from "../converters";
import {ViewActionHandler} from "../action/handlers/view.action-handler";
import {UrlUtils} from "../utilities/url-utils";
import FlightReferenceDisplay from "./flight-reference-display";
import {Icon, Popover, Whisper} from "rsuite";

@inject(
    Element,
    EventAggregator,
    DialogService,
    I18N,
    FlashService,
    CurrencyValueConverter,
    PercentageValueConverter,
    DateValueConverter,
    ViewActionHandler,
    UrlUtils
)

@customElement('flights')
@noView()
export class Flights {

    reactComponent = {};

    @bindable reference;

    constructor(element, ea, dialog, i18n, flash, currencyConverter, percentageConverter, dateConverter, viewActionHandler, urlUtils) {
        this.element = element;
        this.ea = ea;
        this.dialog = dialog;
        this.i18n = i18n;
        this.flash = flash;
        this.currencyConverter = currencyConverter;
        this.percentageConverter = percentageConverter;
        this.dateConverter = dateConverter;
        this.viewActionHandler = viewActionHandler;
        this.urlUtils = urlUtils;

        this.state = {
            links: []
        };
    }

    detached() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    render() {
        this.reference = this.reference.slice(0, 3);

        const listItems = this.reference.map((item) =>
            <FlightReferenceDisplay reference={item}/>
        );

        if (this.reference.length > 0) {
            this.reactComponent = ReactDOM.render(
                <>
                    <p>Flüge
                        <Whisper trigger="hover"
                                 speaker={
                                     <Popover><p>{listItems}</p></Popover>
                                 }>
                            <Icon icon="info" style={{float: "right"}}/>
                        </Whisper>
                    </p>
                </>,
                this.element
            );
        }
    }

    async bind() {
        this.render();
    }
}
