import {inject, bindable, customElement, bindingMode} from "aurelia-framework";
import {Client} from '../api/client';
import {EventAggregator} from "aurelia-event-aggregator";

import "./log.less";
import * as _ from "lodash";
import moment from "moment";

@inject(Client, EventAggregator)
@customElement('sio-log')
export class Log {

    @bindable object;
    @bindable({defaultBindingMode: bindingMode.oneTime}) defaultExpanded = false;

    logs;
    startDate;
    endDate;
    defaultRange;
    loading;
    emptyLoadingIterations;
    appendLabel;

    constructor(client, ea) {
        this.client = client;
        this.ea = ea;
        this.logs = [];
        this.defaultRange = 1;
        this.startDate = moment().endOf('month').format("YYYY-MM-DD");
        this.endDate = moment(this.startDate).startOf('month').format("YYYY-MM-DD");
        this.loading = false;
        this.emptyLoadingIterations = 0;
        this.appendLabelDate = moment(this.endDate).subtract(this.defaultRange,'months').format("DD.MM.YYYY");

        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {
            if (response.config.modelId != 'log/log') {
                return;
            }

            this.logs = [];
            this.defaultRange = 1;
            this.startDate = moment().endOf('month').format("YYYY-MM-DD");
            this.endDate = moment(this.startDate).startOf('month').format("YYYY-MM-DD");
            this.loading = false;
            this.emptyLoadingIterations = 0;

            this.bind();
        });
    }

    bind() {
        this.client.get('log/log-provider', 60).then(provider => {
            this.provider = provider;
        });

        this.getNewLogsFromRange();
    }

    detached()
    {
        this.subscription.dispose();
    }

    append()
    {
        this.startDate = moment(this.endDate).subtract(this.defaultRange,'months').endOf('month').format("YYYY-MM-DD");
        this.endDate = moment(this.startDate).startOf('month').format("YYYY-MM-DD");

        this.getNewLogsFromRange();

        this.appendLabelDate = moment(this.endDate).subtract(this.defaultRange,'months').format("DD.MM.YYYY");
    }

    getNewLogsFromRange() {

        this.loading = true;

        this.client.get('log/' + this.object.modelId + '/' + this.object.id + '/' + this.startDate + '/' + this.endDate).then(logs => {
            _.each(logs, log => {
                this.logs.push(log);
            });

            this.loading = false;

            // load until we have one result, up to 10 times
            if (logs.length === 0 && this.emptyLoadingIterations < 10) {
                this.emptyLoadingIterations++;

                this.append();
            }
        });
    }
}
